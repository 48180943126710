import axios from '@/config/httpConfig'

// 天眼查-搜索列表
export function GetTianyanchaQueryTycList (data) {
  return axios.post('/tianyancha/queryTycList', data)
}

// 天眼查-搜索列表
export function GetTianyanchaQueryTycDetailt (data) {
  return axios.post('/tianyancha/queryTycDetail', data)
}

// 天眼查-回填字段映射
export function GetTianyanchaQueryTycRelation (data) {
  return axios.post('/tianyancha/queryTycRelation', data)
}