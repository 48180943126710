/* 
  天眼查弹框：
  关于回填客户名/回填全部数据的处理：此时回填数据的事件均是使用的编辑表单中的事件。
    1.详情页展示状态：
      点击回填客户名/回填全部数据时，进入编辑状态；
      手动触发回填客户名/回填全部数据事件。
    2.详情页编辑状态：
      点击回填客户名/回填全部数据时，直接执行对应的事件即可。
*/
<template>
  <div>
    <div class="tianyancha">
      <!-- 搜索工商注册信息 -->
      <el-dialog
        :title="$t('label.tianyancha.Search.business')"
        :visible.sync="tianYanChaVisible"
        width="778px"
        :close-on-click-modal="false"
        append-to-body
        custom-class="tianyan"
      >
        <div>
          <!-- 请输入内容 -->
          <el-input
            v-model="input"
            class="inputes"
            :placeholder="$t('label_tabpage_contentz')"
            @change="searchs"
          >
          </el-input>
          <el-button @click="searchs" class="searches">
            <!-- 搜索 -->
            {{ $t("label.quickbooks.searchs") }}
          </el-button>
        </div>
        <div class="exhibition" v-if="isExhibition">
          <!-- 没有数据 -->
          {{ $t("label.errormessage.batchedit.norecord") }}
        </div>
        <!-- 筛选出的客户列表 -->
        <div class="table_content" v-if="isTable">
          <el-radio-group v-model="radio">
            <el-radio
              v-for="(item, index) in data"
              :key="index"
              :label="item.id"
            >
              <div>
                <span class="fw" v-html="item.name"></span>
                <br />
                <span>
                  <span>
                    <!-- 法人 -->
                    {{ $t("label.tianyancha.legalpersonname") }}:
                  </span>
                  <span class="fw">{{ item.legalPersonName }}</span>
                  <span>
                    <!-- 注册资金 -->
                    {{ $t("label.tianyancha.regcapital") }}:
                  </span>
                  <span class="fw">{{ item.regCapital }}</span>
                  <span>
                    <!-- 成立时间 -->
                    {{ $t("label.tianyancha.estiblishtime") }}:
                  </span>
                  <span class="fw">{{ item.estiblishTime }}</span>
                </span>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="see">
            <!-- 查看 -->
            {{ $t("button_partner_viewdetail") }}
          </el-button>
          <el-button @click="customerName">
            <!-- 回填客户名 -->
            {{ $t("label.tianyancha.businessverify.rewritename") }}
          </el-button>
          <el-button @click="whole">
            <!-- 回填全部数据 -->
            {{ $t("label.tianyancha.businessverify.rewriteall") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <div class="see">
      <!-- 工商信息 -->
      <el-dialog
        :title="$t('label.tianyancha.business')"
        :visible.sync="information"
        width="778px"
        :before-close="seeInfo"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <div class="form">
          <el-form
            :inline="true"
            :model="formInline"
            label-width="120px"
            class="demo-form-inline tianyanchaForm"
          >
            <!-- 企业名称 -->
            <el-form-item :label="$t('label.tianyancha.name')">
              <el-input :disabled="true" v-model="formInline.name"></el-input>
            </el-form-item>
            <!-- 法人类型 -->
            <el-form-item :label="$t('label.tianyancha.type')">
              <el-input :disabled="true" v-model="formInline.type"></el-input>
            </el-form-item>
            <!-- 注册号 -->
            <el-form-item :label="$t('label.tianyancha.regnumber')">
              <el-input
                :disabled="true"
                v-model="formInline.registrationNumber"
              ></el-input>
            </el-form-item>
            <!-- 联系方式 -->
            <el-form-item :label="$t('chatter.contact')">
              <el-input
                :disabled="true"
                v-model="formInline.contact"
              ></el-input>
            </el-form-item>
            <!-- 注册资金 -->
            <el-form-item :label="$t('label.tianyancha.regcapital')">
              <el-input
                :disabled="true"
                v-model="formInline.capital"
              ></el-input>
            </el-form-item>
            <!-- 登记机关 -->
            <el-form-item :label="$t('label.tianyancha.reginstitute')">
              <el-input
                :disabled="true"
                v-model="formInline.authority"
              ></el-input>
            </el-form-item>
            <!-- 注册地址 -->
            <el-form-item :label="$t('label.tianyancha.reglocation')">
              <el-input
                :disabled="true"
                v-model="formInline.address"
              ></el-input>
            </el-form-item>
            <!-- 行业 -->
            <el-form-item :label="$t('label.hangye')">
              <el-input
                :disabled="true"
                v-model="formInline.industry"
              ></el-input>
            </el-form-item>
            <!-- 核准时间 -->
            <el-form-item :label="$t('label.tianyancha.approvaltime')">
              <el-input
                :disabled="true"
                v-model="formInline.approvalTime"
              ></el-input>
            </el-form-item>
            <!-- 核准机构 -->
            <el-form-item :label="$t('label.tianyancha.orgapprovedinstitute')">
              <el-input
                :disabled="true"
                v-model="formInline.approvalAuthority"
              ></el-input>
            </el-form-item>
            <!-- 经营范围 -->
            <el-form-item :label="$t('label.tianyancha.businessscope')">
              <el-input
                type="textarea"
                :rows="5"
                :disabled="true"
                v-model="formInline.natureBusiness"
              ></el-input>
            </el-form-item>
            <!-- 组织机构代码 -->
            <el-form-item :label="$t('label.tianyancha.orgnumber')">
              <el-input
                :disabled="true"
                v-model="formInline.organizationCode"
              ></el-input>
            </el-form-item>
            <!-- 成立时间 -->
            <el-form-item :label="$t('label.tianyancha.estiblishtime')">
              <el-input
                :disabled="true"
                v-model="formInline.timeEstablishment"
              ></el-input>
            </el-form-item>
            <!-- 经营状态 -->
            <el-form-item :label="$t('label.tianyancha.regstatus')">
              <el-input
                :disabled="true"
                v-model="formInline.businessStatus"
              ></el-input>
            </el-form-item>
            <!-- 法人 -->
            <el-form-item :label="$t('label.tianyancha.legalpersonname')">
              <el-input
                :disabled="true"
                v-model="formInline.legalPerson"
              ></el-input>
            </el-form-item>
            <!-- 法人ID -->
            <el-form-item :label="$t('label.tianyancha.legalpersonid')">
              <el-input
                :disabled="true"
                v-model="formInline.corporateID"
              ></el-input>
            </el-form-item>
            <!-- 实收注册资金 -->
            <el-form-item :label="$t('label.tianyancha.actualcapital')">
              <el-input
                :disabled="true"
                v-model="formInline.paidRegisteredCapital"
              ></el-input>
            </el-form-item>
            <!-- 网址 -->
            <el-form-item :label="$t('label.mr.title.URL')">
              <el-input
                :disabled="true"
                v-model="formInline.website"
              ></el-input>
            </el-form-item>
            <!-- 公司类型 -->
            <el-form-item :label="$t('label.tianyancha.companyorgtype')">
              <el-input
                :disabled="true"
                v-model="formInline.companyType"
              ></el-input>
            </el-form-item>
            <!-- 省份缩写 -->
            <el-form-item :label="$t('label.tianyancha.province')">
              <el-input
                :disabled="true"
                v-model="formInline.provinceAbbreviation"
              ></el-input>
            </el-form-item>
            <!-- 统一社会信用代码 -->
            <el-form-item :label="$t('label.tianyancha.creditcode')">
              <el-input
                :disabled="true"
                v-model="formInline.unifiedSocialCreditCode"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- <span>这是一段信息</span> -->
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  GetTianyanchaQueryTycList,
  GetTianyanchaQueryTycDetailt,
  GetTianyanchaQueryTycRelation,
} from "./api.js";

export default {
  data() {
    return {
      tianYanChaVisible: false,
      input: "",
      isExhibition: true,
      isTable: false,
      data: [],
      queryTycLists: [], // 公司列表
      radio: "",
      information: false,
      formInline: {
        name: "", //企业名称
        type: "", //法人类型
        registrationNumber: "", //注册号
        contact: "", //联系方式
        capital: "", //注册资金
        authority: "", //登记机关
        address: "", //注册地址
        industry: "", //行业
        approvalTime: "", //核准时间
        approvalAuthority: "", //核准机构
        natureBusiness: "", //经营范围
        organizationCode: "", //组织机构代码
        timeEstablishment: "", //成立时间
        businessStatus: "", //经营状态
        legalPerson: "", //法人
        corporateID: "", //法人ID
        paidRegisteredCapital: "", //实收注册资金
        website: "", //网址
        companyType: "", //公司类型
        provinceAbbreviation: "", //省份缩写
        unifiedSocialCreditCode: "", //统一社会信用代码
      },
      list_account: [], //客户apiname 列表
      list_tyc: [], //天眼查apiname列表
      relation: [], //字段关联关系
    };
  },
  methods: {
    handleCloses() {
      this.tianYanChaVisible = !this.tianYanChaVisible;
      if (this.tianYanChaVisible === false) {
        this.data = [];
        this.queryTycLists = [];
        this.isTable = false;
        this.isExhibition = true;
        this.input = "";
      }
    },
    searchs() {
      if (this.input === "") {
        // 请输入您所要查询的内容
        this.$message.info(this.$i18n.t("vue_label_account_looking_for"));
      } else {
        this.GetTianyanchaQueryTycLists(this.input);
      }
    },
    // 搜索查询公司列表
    async GetTianyanchaQueryTycLists(val) {
      let params = {
        searchWord: val,
      };

      let res = await GetTianyanchaQueryTycList(params);
      if (res.result) {
        if (res.daya === null) {
          this.isExhibition = true;
          this.isTable = false;
        } else if (res.data.length !== 0) {
          this.queryTycLists = JSON.parse(JSON.stringify(res.data));
          this.isExhibition = false;
          this.isTable = true;
          let replace_text = [];
          replace_text.push(val);
          res.data.forEach((item) => {
            for (var i = 0; i < replace_text.length; i++) {
              var replaceString =
                '<span style="color:red;">' + replace_text[i] + "</span>";
              item.name = item.name.replace(
                RegExp(replace_text[i], "g"),
                replaceString
              );
            }
          });
          this.data = res.data;
        } else {
          this.isExhibition = true;
          this.isTable = false;
        }
      } else {
        this.isExhibition = true;
        this.isTable = false;
      }
    },
    // 查看
    see() {
      if (this.radio === "") {
        // 请选择数据
        this.$message.info(this.$i18n.t("label.select.please"));
        return;
      }
      this.information = true;
      this.tianYanChaVisible = false;
      let result = {};
      // 判断是否属于特殊企业，若是特殊企业，直接使用queryTycLists赋值即可。
      let enterpriseData = this.queryTycLists.find(
        (item) => item.id === this.radio
      );
      if (enterpriseData.companyType !== 1) {
        result = enterpriseData;
        this.seeSetValue(result);
        return;
      }
      // 普通企业，需要查详情接口。
      let params = {
        id: this.radio,
      };
      GetTianyanchaQueryTycDetailt(params)
        .then((res) => {
          result = res.data.result;
          this.seeSetValue(result);
        })
        .catch((err) => {});
    },
    // 点击查看获取数据之后赋值
    seeSetValue(result) {
      let that = this.formInline;
      that.name = result.name;
      that.type =
        result.type === 1
          ? this.$i18n.t("label.icon.179")
          : this.$i18n.t("label.company");
      that.registrationNumber = result.regNumber;
      that.contact = result.phoneNumber;
      that.capital = result.regCapital;
      that.authority = result.regInstitute;
      that.address = result.regLocation;
      that.industry = result.industry;
      that.approvalTime = result.approvedTime
        ? this.$moment(result.approvedTime).format("YYYY-MM-DD")
        : "";
      that.approvalAuthority = result.orgApprovedInstitute;
      that.natureBusiness = result.businessScope;
      that.organizationCode = result.orgNumber;
      that.timeEstablishment =
        result.estiblishTime && result.estiblishTime !== "-"
          ? this.$moment(result.estiblishTime).format("YYYY-MM-DD")
          : "";
      that.businessStatus = result.regStatus;
      that.legalPerson = result.legalPersonName;
      that.corporateID = result.legalPersonId;
      that.paidRegisteredCapital = result.actualCapital;
      that.website = result.websiteList;
      that.companyType = result.companyOrgType;
      that.provinceAbbreviation = result.base;
      that.unifiedSocialCreditCode = result.taxNumber;
    },
    seeInfo() {
      this.information = false;
      this.tianYanChaVisible = true;
    },
    // 回填客户名
    customerName() {
      if (this.radio === "") {
        this.$message.info("请选择数据");
        return;
      }
      let params = {
        id: this.radio,
      };
      GetTianyanchaQueryTycDetailt(params)
        .then((res) => {
          let result = res.data.result;
          this.$parent.setTianyanValue(result.name);
          this.tianYanChaVisible = false;
          this.input = "";
          this.data = [];
          this.queryTycLists = [];
        })
        .catch((err) => {});
    },
    // 回填全部数据
    async whole() {
      if (this.radio === "") {
        this.$message.info("请选择数据");
        return;
      }
      await this.GetTianyanchaQueryTycRelations();
      let result = {};
      // 判断是否属于特殊企业，若是特殊企业，直接使用数据queryTycLists赋值即可。
      let enterpriseData = this.queryTycLists.find(
        (item) => item.id === this.radio
      );
      if (enterpriseData.companyType !== 1) {
        result = enterpriseData;
        this.wholeSetValue(result);
        return;
      }
      // 普通企业，需要先查询数据
      GetTianyanchaQueryTycDetailt({ id: this.radio })
        .then((res) => {
          result = res.data.result;
          this.wholeSetValue(result);
        })
        .catch((err) => {});
    },
    /*
     * 点击回填全部数据之后赋值
     * 1.遍历list_tyc，在result对象中匹配apiname拿到字段的value
     * 2.再遍历relation数据，因为系统设置的字段apiname与天眼查拿到的apiname有一个映射关系
     * 3.遍历list_account数据，根据映射关系找到字段在系统中设置的apiname，更新字段的apiname
     */
    wholeSetValue(result) {
      let dataes=[]
      this.list_tyc.forEach((item) => {
        item.value = result[item.apiname];
        this.relation.forEach((items) => {
          if (item.id === items.tianyanchaFieldId) {
            this.list_account.forEach((itemss) => {
              if (itemss.id === items.accountFieldId) {
                item.apiname = itemss.apiname;
                dataes.push(item);
              }
            });
          }
        });
      });
      dataes.forEach((item) => {
        if (item.apiname === "frlx") {
          if (item.value === 1) {
            item.value = "人";
          } else {
            item.value = "公司";
          }
        }
      });
      // this.$parent.setTianyanValuees(dataes);
      this.$emit('setTianyanValuees',dataes)
      this.tianYanChaVisible = false;
      this.input = "";
      this.data = [];
      this.queryTycLists = [];
    },
    async GetTianyanchaQueryTycRelations() {
      let res = await GetTianyanchaQueryTycRelation();
      if (res.result) {
        this.list_account = res.data.list_account;
        this.list_tyc = res.data.list_tyc;
        this.relation = res.data.relation;
      }
    },
    names(val) {
      if (val !== "" && val !== undefined && val !== null) {
        this.input = val;
        this.GetTianyanchaQueryTycLists(val);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .tianyancha {
  .el-dialog__wrapper {
    .el-dialog__header {
      padding: 17px 14px 17px 20px;
      border-bottom: 1px solid #d8d8d8;
      .el-dialog__title {
        font-size: 20px !important;
      }
    }
    .el-dialog__body {
      padding: 16px 36px;
      border-bottom: 1px solid #d8d8d8;
      background-color: #fff;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
      .el-input {
        width: 25%;
        height: 30px;
        margin-right: 10px;
        .el-input__inner {
          height: 100%;
        }
      }
      .el-button {
        padding: 8px 15px !important;
        font-size: 13px;
      }
      .exhibition {
        margin-top: 20px;
        padding-top: 10px;
        border-top: 1px solid #ccc;
      }
    }
    .el-dialog__footer {
      padding: 16px 36px;
      background-color: #fff;
      text-align: center !important;
    }
  }
}
::v-deep .table_content {
  height: 300px;
  margin-top: 20px;
  .el-radio {
    padding: 5px;
    border-top: 1px solid#E0E7Ed;
    line-height: 25px;
    display: flex;
    align-items: center;
    .el-radio__label {
      color: #666666;
      padding-left: 30px;
      font-size: 13px !important;
    }
    .fw {
      font-weight: bold;
      margin-right: 10px;
    }
  }
}

::v-deep .see {
  .el-dialog__wrapper {
    z-index: 2100 !important;
    .el-dialog__header {
      padding: 17px 14px 17px 20px;
      border-bottom: 1px solid #d8d8d8;
      .el-dialog__title {
        font-size: 20px !important;
      }
    }
    .el-dialog__body {
      height: 365px;
      padding: 16px 23px;
      border-bottom: 1px solid #d8d8d8;
      background-color: #fff;
      color: #606266;
      font-size: 14px;
      word-break: break-all;

      .form {
        .demo-form-inline {
          .el-form-item {
            width: 100%;

            .el-form-item__label {
              line-height: 0;
            }
            .el-form-item__content {
              width: 100%;
            }
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 16px 36px;
      background-color: #fff;
      text-align: center !important;
    }
  }
}
::v-deep .tianyanchaForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .el-form-item {
    .el-form-item__label {
      height: 0 !important;
      padding-left: 5px !important;
    }
  }
}

::v-deep .searches {
  padding: 8px 20px !important;
  margin-left: 10px !important;
}

::v-deep .inputes {
  width: 300px;
  height: 30px;
  .el-input__inner {
    height: 100%;
  }
}

::v-deep .radios {
  .el-radio__input {
    padding-right: 10px;
  }
  .el-radio__label {
    display: none;
  }
}
::v-deep .el-textarea {
  width: 202px;
}
</style>